<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="object-map__root"
  >
    <BigForm
      :title="props.values.title"
      :image-id="props.values.image"
      :message="props.values.message"
      :type="props.values.name_form"
      :ym="cfgs.reach_goal_yandex_metrika"
    />
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import BigForm from '~/cms/blocks/form-message-big-block/BigForm.vue';

type FormMessageBigBlockPropsValues = {
	image: number;
	message: string;
	name_form: string;
	title: string;
}

type FormMessageBigBlock = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
	values: FormMessageBigBlockPropsValues;
	cfgs: SectionTypes & {
		reach_goal_yandex_metrika?: string;
	};
}

const props = defineProps<FormMessageBigBlock>();

const { $customSectionClasses } = useNuxtApp();

</script>
